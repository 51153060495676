<template>
  <div class="row mt--lg">
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Placa:</b> {{ vehicle.license_plate }}
      </getecma-p>
    </div>
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Modelo:</b> {{ model ? model.name : 'Carregando...' }}
      </getecma-p>
    </div>
    <!--<div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Cor selecionada: </b>
        <svg height="32" width="32">
          <circle cx="16" cy="16" r="15" :fill="vehicle.color_hex" />
        </svg>
      </getecma-p>
    </div>-->
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Rotas:</b> <span v-for="region in regions" :key="region.id"><br>• {{ region.name }} </span>
      </getecma-p>
    </div>
  </div>
</template>

<script>
import { toastError } from '@/services/toastService';
import { getModelById } from '@/modules/model/model.service';
import { fetchRegionsByVehicle } from '@/modules/vehicle/vehicle.service';

export default {
  name: 'GetecmaVehicleViewInformation',
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: null,
      regions: [],
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      getModelById(this.vehicle.model_id)
        .then(data => {
          this.model = data;
        })
        .catch(() => toastError('Erro ao obter modelo por ID'));

      fetchRegionsByVehicle(this.vehicle.id)
        .then(data => {
          this.regions = data.rows;
        })
        .catch(() => toastError('Erro ao obter clientes por grupo'));
    },
  },
};
</script>
